@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.amplayer-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
[id^="vjs_video_"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.vjs-control-bar {
  padding: 0;
}
.vjs-play-progress {
  background-color: "#f7993b";
  background: "#f7993b";
}
.vjs-slider-handle::before {
  background-color: "#f7993b" !important;
  background: "#f7993b" !important;
  height: 4px !important;
  margin: -2px 0 0 -2px !important;
}

video.azuremediaplayer {
  /* // top: 0; // This hides the weird black box popup */
  display: none;
}

.vjs-has-started .vjs-control-bar {
  display: table;
}

.amp-default-skin .vjs-loading-spinner {
  position: absolute;
  background: none;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  width: 50px;
  height: 50px;
}
.vjs-loading-spinner::before {
  /* Removes the broken AMP spinner */
  visibility: hidden;
}
.vjs-lock-showing {
  display: block !important;
}

.vjs-modal-dialog::before {
  display: none;
}
.vjs-modal-dialog-content {
  font-size: 1rem !important;
  width: 50% !important;
}
.azuremediaplayer {
  font-size: 1rem !important;
}
.vjs-big-play-button::before {
  font-size: 2rem !important;
}
.azuremediaplayer .vjs-has-started .vjs-control-bar {
  display: table;
}
.vjs-menu {
  height: 3rem !important;
}
.vjs-selected:hover {
  background-color: rgba(95, 120, 152, 0.8) !important;
  color: #fff !important;
  font-weight: bolder !important;
}
